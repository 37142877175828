.roadmap{
    width: 100%;
    /* height: 90vh; */
    background-color: #000;
    color: #fff;
    padding: 3vw 0;
    /* overflow: auto; */
    position: relative;
}
.roadmap .text1{
    width: 100%;
    text-align: center;
    font-size: 1.8vw;
    font-weight: 500;
}
.roadmap .text2{
    width: 100%;
    text-align: center;
    font-size: 1vw;
    font-weight: 400;
    margin-top: 3vw;
}
.roadmap .step{
    width: 60vw;
    margin: 3vw auto;
    align-items: flex-start;
}

.roadmap .shadow{
    width: 100%;
    height: 45vw;
    position: absolute;
    bottom: 0;
    background: url(../assets/satsale/Vector4.png) no-repeat;
    background-size: 100% 100%;
}

.stepImg{
    width: 15.8vw;
    height: 13.4vw;
    position: relative;
}
.stepImg .coverStep{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.69);
    border-radius: 2vw;
}

.Img1{
    background: url(../assets/satsale/Maskgroup1.png) no-repeat;
    background-size: 100% 100%;
}
.Img2{
    background: url(../assets/satsale/Maskgroup2.png) no-repeat;
    background-size: 100% 100%;
}
.Img3{
    background: url(../assets/satsale/Maskgroup3.png) no-repeat;
    background-size: 100% 100%;
}
.stepTitle{
    width: 15.8vw;
    text-align: center;
    margin: 1vw auto;
    text-transform: uppercase;
    font-size: 1.2vw;
    font-weight: 700;
}

.stepLi{
    width: 15.8vw;
    text-align: left;
    font-size: 0.8vw;
    font-weight: 400;
}

.phase{
    position: absolute;
    bottom: 0;
    left: 38%;
    z-index: 10;
    font-size: 0.9vw;
    font-weight: 500;
}