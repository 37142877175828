.load {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999999;
  color: #000;
}
.load .modalContent{
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(3, 3, 3);
  background: rgba(24, 24, 24, 0.5);
}
.load .modalContent .text{
  width: 100%;
}
.load .modalContent .text .modalLine{
  width: 6.25rem;
  height: .1875rem;
  background: #FFF;
  margin: 0 auto;
  margin-bottom: 1.25rem;
}

.load .modalContent .loading{
  width: 100%;
  height: 100%;
  background: rgb(221, 226, 226, 0.25);
  display: flex;
  color: #f1f1f1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.load .modalContent .modals{
  width: 45rem;
  height: 24rem;
  color: #000;
  background: url('../../assets/modal-bg.png') no-repeat;
  background-size: 100% 100%;
  border-radius: 1.25rem;
  padding-bottom: 2.5rem;
  padding-top: 2rem;
  position: relative;
}
.modals .text .modalTitle{
  font-size: 1.875rem;
  text-align: center;
  margin-bottom: .625rem;
  color:#000;
}

.modals .text .modalText{
  margin-top: 4.125rem;
  text-align: center;
  font-size: 1.25rem;
  color:#000;
  word-wrap: break-word;
}
.load .modalContent .modals .modalClose{
  width: 10rem;
  padding: 0.5rem 0.5rem;
  background: #F7B500;
  border: none;
  border-radius: 6px;
  display: flex;
  align-self: center;
  margin: 6.25rem auto;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  color: #000;
  cursor: pointer;
}
.contents{
  width: 570px;
  height: 725px;
  background: #FFF;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.16);
  border-radius: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 17px 94px 0;
  font-family: Poppins;
}
.repayText{
  font-size: 24px;
  font-weight: 600;
  line-height: 35px;
  margin-bottom: 60px;
}
.repayFlex{
  width: 357px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
}
.repayFlex .key{
  font-size: 18px;
}
.repayFlex .value{
  font-size: 16px;
}
.repayTitle .repayERC{
  font-size: 24px;
}
.repayTitle .repayTime{
  font-size: 18px;
}
.repayImg{
  width: 382px;
  height: 349px;
  margin-bottom: 13px;
}
.repayImg img{
  width: 100%;
  height: 100%;
}
.repayNum{
  margin-bottom: 36px;
}
.repayNum .value{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.repayNum .value img{
  width: 10px;
  height: 15px;
  margin-right: 4px;
}
.repayBtn{
  width: 231px;
  height: 48px;
  background: rgb(31, 31, 31);
  border-radius: 30px;
  color: #FFF;
  line-height: 48px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}


@media only screen and (max-width: 520px) {
  .load .modalContent .modals {
    width: 26rem;
  }
}

@media only screen and (max-width: 375px) {
  .load .modalContent .modals {
    width: 24rem;
  }
}



.refreshbg{
  width: 500px;
  /* height: 350px; */
  background: url(../../assets/popupbg.png) no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.refreshbg .close{
  width: 100%;
  height: 50px;
}
.refreshbg .close img{
  width: 30px;
  height: 30px;
  position: absolute;
  right: 40px;
  top: 30px;
  cursor: pointer;
}
.refreshbg .textpop{
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-family: Futura-Medium, Futura;
  font-weight: 500;
  color: #A2C529;
  margin: 50px auto;
  padding: 0 30px;
}
.refreshbg .button{
  width: 130px;
  height: 45px;
  /* line-height: 45px; */
  background: linear-gradient(180deg, #B2DBAC 0%, #B3E620 43%, #D3DA85 100%);
  border-radius: 8px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  margin: 0 auto 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 520px){
  .refreshbg{
    width: 90%;
  }
  .refreshbg .textpop{
    margin: 30px auto;
    font-size: 16px;
    padding: 0 10px;
  }
  .refreshbg .button{
    width: 110px;
    height: 35px;
    font-size: 13px;
    margin: 0 auto 30px;
  }
  .refreshbg .close img{
    width: 25px;
    height: 25px;
  }
}