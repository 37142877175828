.dropdown {
  position: relative;
  display: block;
  width: 7.4vw;
  padding: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown>img {
  width: 2.5vw;
  height: 2.5vw;
  margin-top: -0.1vw;
  margin-right: -0.5vw;
  vertical-align: middle;
}

.connect-button {
  /* width: 7.9167vw; */
  /* min-width: 7.4vw; */
  font-family: 'Eras_Bold_ITC';
  position: relative;
  display: inline-block;
  padding: .625vw 1.75vw .5vw;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  font-size: 16px;
  line-height: 18px;
  color: #000;
  cursor: pointer;
  background-color: #5FBCFF;
  border-radius: 2.1vw;
  text-transform: uppercase;
  /* -webkit-text-stroke: .0526vw #000000; */
  /* text-shadow: 0 0 5px #F9FA01; */
}

.connect-button-connected {
  /* color: #FFF; */
}

.wallet-icon {
  width: 1.3542vw;
  height: 1.3542vw;
  /* margin-right: .625vw; */
  display: none;
}

.dropdown-content {
  width: 7.9167vw;
  display: none;
  position: absolute;
  top: 2.4vw;
  right: 0;
  margin: 0 auto;
  text-align: center;
  background: #33333395;
  padding: .625vw .8333vw;
  z-index: 99;
  font-size: .625vw;
  color: #ffffffa0;
}

.dropdown-content p {
  padding: .1042vw;
  font-size: .7292vw;
  color: #FFF;
}

.dropdown:hover .dropdown-content {
  display: block;
  cursor: pointer;
}

.dropdown-content .hide {
  display: none;
}

@media (max-width: 520px) {
  .connect-button {
    font-size: 2.3vw;
    padding: 0.8vw 1vw 1vw;
  }

  .dropdown>img {
    width: 4vw;
    height: 4vw;
    /* margin-top: 0.2vw; */
  }

  .dropdown-content {
    width: 22vw;
    top: 1.3rem;
    right: -2.2rem;
    padding: 1.5vw 1vw;
  }

  .dropdown-content p {
    font-size: 2.5vw !important;
    color: #FFF;
  }

  /* .dropdown{
    width: 5.125rem;
  }
  .dropdown>img{
    width: 1.0625rem;
    height: .9375rem;
  }
  .connect-button{
    width: 4.25rem;
    font-size: 9px;
    padding: 0.75rem 0.2rem;
  }
  .dropdown-content {
    top: 2.25rem;
    right: 2.5rem;
  } */
}

/* .stroked-button-text {
  text-shadow:
   -1px -1px 0 #F9FA01,  
   1px -1px 0 #F9FA01,
   -1px 1px 0 #F9FA01,
   1px 1px 0 #F9FA01,
   -1px -2px 0 #F9FA01,  
   1px -2px 0 #F9FA01,
   -1px 2px 0 #F9FA01,
   1px 2px 0 #F9FA01,
   -2px -1px 0 #F9FA01,  
   2px -1px 0 #F9FA01,
   -2px 1px 0 #F9FA01,
   2px 1px 0 #F9FA01,
   2px 2px 2px rgb(39, 1, 1);
}; */