.contentPad {
    background: #000;
}

.launchPad {
    background: #FFFFFF;
    background-size: 100%;
    padding-top: 3.1771vw;
    box-sizing: border-box;
    font-family: 'Eras_Bold_ITC';
    user-select: none;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.width {
    width: 56.25vw;
    margin: 0 auto;
}

.partOne {
    position: relative;
    margin-bottom: 5.2083vw;
    position: relative;
    z-index: 9;
    width: unset;
}

.partOne .partOneL {
    flex-direction: column;
    justify-content: space-between;
    margin-right: .625vw;
}

.partOneL .upImg img {
    width: 42.9167vw;
    height: 21.8229vw;
}

.partOneL .upImg .upup {
    width: 15.1563vw;
    height: 14.7396vw;
    background: #646464;
    border-radius: 1.6667vw;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
}

.partOneL .upImg .downdown {
    width: 12.9688vw;
    height: 13.8542vw;
    background: #CCCCCC;
    border-radius: 1.6667vw;
    position: absolute;
    right: 0;
    bottom: 0;
}

.partOneL .downIcon {
    width: 100%;
    justify-content: flex-start;
    padding-left: 2.0833vw;
}

.partOneL .downIcon a {
    display: block;
    width: 1.6146vw;
    height: 1.6146vw;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin: 0 .5208vw;
}

.partOneL .downIcon a .dc {
    width: 1.3542vw;
    height: .9958vw;
}

.partOneL .downIcon a .tw {
    width: 1.1688vw;
    height: .9656vw;
}

.partOneL .downIcon a .web {
    width: 1.0938vw;
    height: 1.0938vw;
}

.partOneL .downIcon a .tg {
    width: 1.2328vw;
    height: .9594vw;
}

.partOneR {
    width: 18.1146vw;
    height: 20.2083vw;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.partOneR div {
    font-family: 'Eras_Bold_ITC';
    font-style: normal;
    font-weight: 400;
    font-size: 1.0417vw;
    line-height: 1.0417vw;
    letter-spacing: 0.06em;
}

.partOneR .context {
    margin-bottom: 1.2396vw;
}

.partTwoOutSide {
    height: 19.6875vw;
    background: #FF845A;
    padding: 3.5938vw 0 3.3854vw 0;
    box-sizing: border-box;
    border-top: 0.2604vw solid #000000;
}

.partTwo {
    width: 60.1563vw;
    margin: 0 auto;
    margin-bottom: 2.6042vw;
    position: relative;
}

.partTwo .foodTitle {
    display: flex;
    align-items: center;
    margin-bottom: 2.0833vw;
}

.partTwo .foodTitle>img {
    width: 4.1667vw;
    height: 4.1667vw;
    border-radius: 50%;
    margin-right: 1.0417vw;
}

.partTwo .foodTitle .cctt .ccttLogo {
    font-family: 'Eras_Bold_ITC';
    font-style: normal;
    font-weight: 700;
    font-size: 2.1875vw;
    line-height: 2.5521vw;
}

.partTwo .foodTitle .cctt>div {
    font-style: normal;
    font-weight: 700;
    font-size: 1.0417vw;
    line-height: 1.1979vw;
    letter-spacing: 0.08em;
}

.partTwo .scellection {
    width: 20.8333vw;
    height: 21.7708vw;
    position: absolute;
    left: -21vw;
    bottom: -7.5vw;
}

.partTwo .statusTime {
    justify-content: space-between;
    margin-bottom: .7813vw;
    font-weight: 700;
    font-size: 1.0417vw;
    line-height: 1.1979vw;
    text-transform: uppercase;
}

.partTwo .statusTime .status {
    margin-right: 2.3438vw;
}

.partTwo .statusTime .startTime {
    font-style: normal;
    font-weight: 700;
    font-size: .8333vw;
    line-height: .9896vw;
    text-transform: uppercase;
    /* color: #5FBCFF; */
}

.partTwo .progcss {
    width: 60.1563vw;
    height: 1.5104vw;
    /* background: #FF845A; */
    border: .1042vw solid #000000;
    border-radius: .8333vw;
    position: relative;
    margin-bottom: .5208vw;
}

.partTwo .progcss .innerBg {
    height: 100%;
    background: #43EFA4;
    border-radius: .8333vw;
    overflow: hidden;
}

.partTwo .progcss .innerBtc {
    width: 7.8125vw;
    height: 100%;
    text-align: right;
    font-size: .9375vw;
    text-transform: uppercase;
    /* color: #FF845A; */
    position: absolute;
    bottom: 0;
    left: 0;
}

.partTwo .progcss .innerBtc2 {
    width: 7.8125vw;
    height: 100%;
    text-align: left;
    font-size: .9375vw;
    text-transform: uppercase;
    /* color: #5FBCFF; */
    position: absolute;
    bottom: 0;
    left: 0;
}

.partTwo .progcss .innerCCTT {
    position: absolute;
    bottom: -0.5208vw;
    left: 4.6875vw;
    width: 1.9792vw;
    height: 2.4479vw;
}

.partTwo .BtcNum {
    width: 60.1563vw;
    justify-content: space-between;
    color: #000000;
}


.partThree {
    width: 100%;
    height: 15.625vw;
    /* background: #5FBCFF; */
    border-top: .2604vw solid #000000;
    border-bottom: .2604vw solid #000000;
}

.partThree .totalContent {
    width: 60.1563vw;
    align-items: flex-start;
    justify-content: space-between;
}

.partThree .totalContent .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2.0833vw 0;
}

.partThree .totalContent .option {
    width: 26.0417vw;
    font-style: normal;
    font-weight: 700;
    font-size: .9375vw;
    line-height: 1.0938vw;
    text-transform: uppercase;
    color: #000000;
}

.partThree .totalContent .option .opVal {
    width: 4.1667vw;
    text-align: left;
}

.partFour .buyContent {
    margin-bottom: 5.8333vw;
}

.partFour .buyContent .numInput {
    padding-top: 1.0417vw;
    position: relative;
    margin-bottom: 4.4271vw;
}

.partFour .buyContent .numInput .btcScope {
    width: 100%;
    font-size: .7292vw;
    line-height: .8333vw;
    margin-bottom: .3125vw;
}

.partFour .buyContent .numInput .QQQ {
    width: 3.125vw;
    height: 2.5vw;
    position: absolute;
    top: 0.5vw;
    right: 2.0833vw;
}

.partFour .buyContent .numInput .maxBtn {
    position: absolute;
    width: 7.7604vw;
    height: 3.4896vw;
    background: #000;
    border-radius: 1.7188vw;
    font-size: 1.4583vw;
    line-height: 3.4896vw;
    color: #FFF;
    text-align: center;
    position: absolute;
    right: .9375vw;
    bottom: .7292vw;
    cursor: pointer;
}

.partFour .buyContent .numInput .btcIcon {
    position: absolute;
    left: 1.4583vw;
    bottom: 1.83vw;
}

.partFour .buyContent .numInput .inputBtc {
    width: 33.6458vw;
    height: 5.0521vw;
    background: #FFF;
    border: .1042vw solid #000;
    border-radius: 3.2813vw;
    text-indent: 4.1667vw;
    font-size: .9375vw;
    line-height: 1.0938vw;
}

.partFour .buyContent .buyBtns {
    width: 33.6458vw;
    height: 5.0521vw;
    background: #000;
    border-radius: 3.2813vw;
    color: #FFF;
    font-size: 1.3542vw;
    cursor: pointer;
    border: unset;
}

.partFour .buyContent .buyBtns:hover {
    background: #000;
    color: #FFF;
}

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
    background: #545454 !important;
    color: #FFF !important;
}

.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
    background: #000 !important;
    color: #FFF !important;
}

.bottomCard {
    width: 62.1875vw;
    height: 12.9688vw;
    background: #FFF;
    border: .2604vw solid #000000;
    margin: 0 auto;
    padding: 0 3.4375vw;
    justify-content: space-between;
    position: relative;
}

.bottomCard .foodTitle {
    display: flex;
    align-items: center;
}

.bottomCard .foodTitle>img {
    width: 4.1667vw;
    height: 4.1667vw;
    border-radius: 50%;
    margin-right: 1.0417vw;
}

.bottomCard .foodTitle .cctt .ccttLogo {
    font-family: 'Eras_Bold_ITC';
    font-style: normal;
    font-weight: 700;
    font-size: 2.1875vw;
    line-height: 2.5521vw;
}

.bottomCard .foodTitle .cctt>div {
    font-style: normal;
    font-weight: 400;
    font-size: 1.0417vw;
    line-height: 1.1979vw;
    color: #000;
}

.partTwo .foodTitle .cctt .statusPublic {
    color: #000;
}

.bottomCard .cardRight {
    text-align: right;
    font-size: .9896vw;
    line-height: 1.1458vw;
    font-size: 1.7708vw;
    font-weight: 700;
    line-height: 2.0313vw;
}

.bottomCard .cardRight .aaa {
    color: #000;
    /* -webkit-text-stroke: .0781vw #000000; */
    margin-bottom: .625vw;
}

.bottomCard .cardRight .bbb {
    color: #000;
    font-family: Eras Bold ITC;
    font-size: 1.1938vw;
    font-weight: 700;
    line-height: 1.25vw;
}

.bottomCard .softNotFull {
    font-size: 1.6667vw;
    font-weight: 700;
    line-height: 1.9271vw;
}

.bottomCard .softNotFull .fullUptext {
    margin-bottom: 1.13vw;
}

.bottomCard .softNotFull .fullUptext span {
    color: #F90000;
}

.bottomDuck {
    width: 17.9688vw;
    height: 9.8958vw;
    position: absolute;
    right: -17.8VW;
    bottom: -11.5vw;
}

.bottomDuck1 {
    width: 17.4479vw;
    height: 25.2604vw;
    position: absolute;
    left: -18.8VW;
    bottom: -11.5vw;
}

.bottomDuck2 {
    width: 17.7083vw;
    height: 24.7396vw;
    position: absolute;
    right: -15.8VW;
    bottom: 12.5vw;
}




.bottomBgColor {
    background: #43EFA4;
    padding-bottom: 18.2813vw;
    padding-top: 3.125vw;
}

.claimePart {
    width: 50.5208vw;
    margin: 0 auto;
    flex-direction: column;
    align-content: space-between;
}

.claimePart .upShowData {
    width: 42.1875vw;
    justify-content: space-between;
    margin-bottom: 3.8021vw;
    font-style: normal;
    font-weight: 700;
    font-size: 1.4583vw;
    line-height: 1.6667vw;
    text-transform: uppercase;
}

.claimePart .claimEnableTimestamp {
    text-align: center;
}

.claimePart .downShowData {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 3.0729vw;
}

.claimePart .downShowData .chooseGasOption {
    width: 13.8542vw;
    height: 7.1875vw;
    background: #FFFFFF;
    border: .2083vw solid #000000;
    border-radius: .625vw;
    padding: .9375vw 0 1.3021vw 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 1.25vw;
    line-height: 1.4583vw;
    text-transform: uppercase;
}

.claimePart .buyBtns {
    width: 33.6458vw;
    height: 5.0521vw;
    background: #000;
    border-radius: 3.2813vw;
    color: #FFFFFF;
    font-size: 1.3542vw;
    cursor: pointer;
    border: unset;
    margin-bottom: 6.1979vw;
}

.claimePart .buyBtns:hover {
    background: #000;
    color: #FFFFFF;
}

.claimePart .buyBtnsgrey {
    background: #545454;
}

.claimePart .ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
    background: #000 !important;
    color: #FFF;
}

.claimePart .downShowData .chooseGasOption .downShow span {
    font-weight: 700;
    font-size: 2.0833vw;
    line-height: 2.3958vw;
    text-transform: uppercase;
}

.claimePart .downShowData .activeBorder {
    border: .4167vw solid #000;
    padding: .7292vw 0 1.0938vw 0;
}