.cover {
    width: 100%;
    min-height: 100vh;
    background: #fff;
    display: flex;
    /* top: -2.5rem; */
    position: absolute;
}

.common-header {
    padding: 1.1458vw 4.5vw;
    width: 100%;
    background-color: #000;
}

.common-header .nav {
    margin-left: -27vw;
    font-size: 1.25vw;
    font-family: AaHouDiHei;
    font-weight: 400;
}

a,
a:visited,
a:link,
a:hover,
a:active {
    text-decoration: none;
    color: #fff !important;
}

.nav a {
    padding: 0.3vw 0.7vw;
    text-decoration: none;
    color: #FFF !important;
    margin: 0 1.3958vw;
    display: block;
}

.nav a .TT {
    width: 1.875vw;
    height: 1.875vw;
    color: #FFF;
}

.nav a .DC {
    width: 2.0833vw;
    height: 1.6667vw;
}

.nav a .OS {
    width: 2vw;
    height: 2vw;
}

.nav .stateActive {
    width: 8.3854vw;
    height: 2.6042vw;
    background: #FFFFFF;
    border-radius: 1.3021vw;
    color: #000 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}



.title {
    color: #fff;
}

.title img {
    /* width: 3vw; */
    height: 1.875vw;
}

.title span {
    font-weight: 500;
    font-size: 1.1vw;
}


.left-box .copyright {
    font-size: .625vw;
    color: #fff;
    margin-top: 1vw;
}

.titlelist {
    margin-bottom: 2vw;
}

.titlelist p {
    color: #D4DC00;
    margin-bottom: .3125vw;
}

.logolist {
    position: absolute;
    /* float: right; */
    right: 14vw;
    margin: -2.9vw 1.25vw 0 0;
}

.logolist img {
    width: 1.5vw;
    height: 1.5vw;
    margin-right: 1vw;
}

.earthbox {
    width: 4vw;
    height: 2vw;
    line-height: 1.8vw;
    text-align: center;
    position: absolute;
    right: 23vw;
    margin-top: -2.98vw;
    cursor: pointer;
}

.earth {
    width: 1.4vw;
    height: 1.3vw;
}

.languagelist {
    display: none;
    width: 5vw;
    background: #fff;
    opacity: 0.82;
    position: absolute;
    right: 22.3vw;
    margin-top: -1vw;
}

.languagelist .lan {
    width: 100%;
    height: 2.2vw;
    line-height: 2vw;
    text-align: center;
    font-size: 0.8vw;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #A2C529;
    cursor: pointer;
    user-select: none;
}

.laned {
    background: #A2C529 !important;
    opacity: 0.47 !important;
    color: #fff !important;
}

.earthbox:hover+.languagelist {
    display: block;
}

.languagelist:hover {
    display: block;
}


.cover .right-box {
    width: 100%;
    min-height: 100vh;
}

.bgtop {
    width: 100%;
    /* height: 53vw; */
    background: url(../assets/bg-top.png) no-repeat;
    background-size: 100% 100%;
    padding: 1vw 2vw;
    position: relative;
}

.head-connect {
    width: 11.5vw;
    height: 2.5vw;
    padding: 0 0.5vw;
    border: .1042vw solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.5625vw;
    float: right;
    margin: -3.5vw 2vw 2vw 0;
}

.right-box .firstline {
    /* display: flex;
    justify-content: center; */
    width: 60vw;
    margin: 8vw auto 5vw;
}

.linewidth {
    width: 47.7vw;
}

.linewidth-A {
    width: 44.7vw;
}

.linewidth-B {
    width: 58vw;
}

.linewidth-C {
    width: 65vw;
}


.mintBtn {
    width: 7.5vw;
    height: 2.2vw;
    line-height: 2.2vw;
    margin: 2vw auto 0;
    text-align: center;
    background: linear-gradient(180deg, #B2DBAC 0%, #B3E620 43%, #D3DA85 100%);
    color: #fff;
    border-radius: .5vw;
    font-family: Poppins;
    font-size: 1vw;
    font-weight: bold;
    cursor: pointer;
}

.mintBtned {
    background: #b0b0b0 !important;
    opacity: 0.75;
    cursor: default !important;
}

.right-box .firstline .firstline-left {
    /* width: 50%; */
    margin-right: 1.5625vw;
}

.imglink {
    width: 18.75vw;
    height: 18.75vw;
    display: block;
    margin: 0 auto;
}


.textPub {
    color: #fff;
    margin: 0 auto;
    text-align: center;
}

.Pri {
    font-size: 1.8vw;
    font-weight: 500;
    font-family: Futura-Medium, Futura;
}

.Pri3 {
    font-size: 1.65vw;
    font-weight: 500;
    font-family: Futura-Medium, Futura;
}

.Pri33 {
    font-size: 1.3vw;
    font-weight: 500;
    font-family: Futura-Medium, Futura;
}

.onePri {
    font-size: 4.3vw;
    font-family: poppins-b;

}

.onePriBottom {
    margin-bottom: 1vw;
}

.twoPri {
    font-size: 1.3vw;
    font-family: Futura-Medium, Futura;
    font-weight: 500;
}

.threePri {
    font-size: 1.1vw;
    font-family: Poppins;
    font-weight: bold;
    margin-bottom: 3.5vw;
}

.threePri-Buy {
    font-size: 0.8vw;
    font-family: Poppins-Medium, Poppins;
    font-weight: bold;
}


.copyBtn {
    width: 9vw;
    height: 2vw;
    line-height: 2vw;
    margin: 0.5vw auto;
    text-align: center;
    color: #fff;
    font-family: Poppins;
    font-size: 0.9vw;
    /* border-radius: .5vw; */
    border-radius: 1vw;
    font-weight: bold;
    background-color: #A2C529;
    cursor: pointer;
    user-select: none;
    border: .0521vw solid #FFFFFF;
    /* background: linear-gradient(to right,#189E29,#D4DC00,#D4DC00); */
}

.launch {
    display: block;
    margin-top: 4vw !important;
}

.buyBtn {
    margin-bottom: 8vw !important;
}

.bottomPub {
    margin-bottom: 3.5vw !important;
}

.linkbox {
    width: 20vw;
    border-radius: 1.5vw;
    border: 0.125vw solid #FFFFFF;
    font-size: 0.8vw;
    font-family: Poppins-Medium, Poppins;
    font-weight: bold;
    color: #FFFFFF;
    padding: 0.5vw;
    text-align: center;
    margin: 1vw auto;
}

.copyBtn .link {
    width: 1.2vw;
    height: 1.2vw;
    margin: -0.15vw 0.2vw 0 0;
    /* margin-right: .2vw; */
    vertical-align: middle;
}

.firstline .firstline-right .textPub-a {
    text-align: center;
    margin: 0 auto;
    margin-top: 1vw;
    color: #fff;
    font-size: 1.1vw;
    font-family: Poppins-Medium, Poppins;
    font-weight: 500;
}

.textPub-a span {
    color: #F9FA01;
}

.kor {
    font-family: AppleSDGothicNeo-Medium, AppleSDGothicNeo !important;
}

.firstline .firstline-right .textPub-b {
    text-align: center;
    margin: 0 auto;
    color: #F9FA01;
    font-size: 1.3vw;
    font-family: Poppins;
    font-weight: bold;
}

.right-box .secondline {
    margin: 0 auto;
}

.littertitle {
    font-size: 2.25vw;
    font-weight: 500;
    color: #A2C529;
    font-family: Futura-Medium, Futura;
    text-align: center;
    margin: 2.1875vw auto 0;
}

.stepall {
    display: flex;
    justify-content: space-between;
    margin-top: 1vw;
}

.stepall .steptitle {
    color: #A2C529;
    font-weight: 500;
    font-size: 1vw;
    font-family: Futura-Medium, Futura;
    text-align: center;
    margin: 0.5vw 0;
}

.stepall .steptext {
    width: 13.4vw;
    /* width: 10.9vw; */
    padding: 0 .625vw;
    font-size: .75vw;
    text-align: center;
    font-weight: 500;
    color: #B4E626;
    font-family: PingFangSC-Medium, PingFang SC;
}

.stepall .step {
    width: 13.4vw;
    height: 13.4vw;
    background-color: #F9F9F9;
    margin: 1vw 0 0.5vw;
    border-radius: 1.125vw;
    text-align: center;
    padding: 0.5vw 1vw;
}

.stepall .step img {
    width: 4vw;
    height: 4vw;
    margin-top: 1vw;
}

.stepall .step .textstep {
    color: #A2C529;
    font-size: .8vw;
    font-weight: 500;
    margin-top: 2.2vw;
    font-family: Futura-Medium, Futura;
}

.circleimg {
    width: 3.75vw;
    height: 3.75vw;
    background: url(../assets/circle.png) no-repeat;
    background-size: 100% 100%;
    margin-top: 5.5vw;
}

.right-box .thirdlyline {
    width: 100%;
    /* height: 20vw; */
    background: url(../assets/bg-middle.png) no-repeat;
    background-size: 100% 100%;
    margin: 4vw auto 2vw;
    padding: 1vw 20vw;
}

.thirdlyline .abouttext {
    font-size: .78vw;
    font-family: Futura-Medium, Futura;
    font-weight: 500;
    color: #FFFFFF;
    margin: 1vw 0 6vw;
}

.right-box .fourthlyline {
    margin: 2vw auto 5vw;
    /* height: 23vw; */
}

.right-box .fourthlyline .questionlist {
    /* display: grid;
    grid-template-columns: repeat(2,25rem);
    grid-template-rows: repeat(2,2.8125rem);
    grid-gap:1.25rem; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    /* position: relative; */
    user-select: none;
    margin-top: 1vw;
}

.fourthlyline .question {
    width: 27vw;
    background: linear-gradient(70deg, #B2DBAC 0%, #B4E338 68%, #B4E330 100%);
    border-radius: 2vw;
    font-size: 0.8vw;
    color: #fff;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    padding: 0.8vw 1.5vw;
    cursor: pointer;
    margin-top: 2.5vw;
}

/* .questionhigh{
    width: 27vw;
    background: linear-gradient(70deg, #B2DBAC 0%, #B4E338 68%, #B4E330 100%);
    border-radius: 2vw;
    font-size: 0.8vw;
    color: #fff;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 500;
    padding:0.25vw 2vw;
    cursor: pointer;
    margin-top: 1.0417vw;
} */

.korSize {
    font-size: 0.75vw;
}

.enSize {
    font-size: 0.7vw !important;
    font-family: Futura-Medium, Futura !important;
}

.questionunfold {
    /* transition: height 1s linear;
    height: auto; */
    transition: background 0.3s linear;
    background: #fff !important;
    color: #A2C529 !important;
}

.fourthlyline .rightlist-Q {
    /* position: absolute;
    right: 0;
    top: -1.27rem; */
    /* position:relative;
    left: 32vw;
    top: -17.47vw; */
}

.answer {
    color: #A2C529;
    font-size: .75vw;
    font-weight: 400;
    line-height: 1.5625vw !important;
    margin: .625vw 0 0 1.8vw;
    font-family: PingFangSC-Regular, PingFang SC;
}

.answerleft {
    margin-left: 2vw !important;
}

.answeren {
    font-family: Futura-Medium, Futura !important;
}

.img-QQ {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.textleft {
    width: 20vw;
    margin-left: 0.5vw;
}

.img-Q img {
    width: 1vw;
    height: 1vw;
    /* float: right; */
    margin: 0.1vw 0 0 1vw;
}

/* .questionhigh img{
    width: 1vw;
    height: 1vw;
    margin: 0.6vw 0 0 1vw;
} */

.fifthline {
    width: 100%;
    height: 15vw;
    background: url(../assets/bg-bottom.png) no-repeat;
    background-size: 100% 100%;
    margin-top: 7vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2vw 20vw 0;
}

.fifthline .logo-a {
    width: 4vw;
    height: 4.5vw;
    background: url(../assets/logo-gg.png) no-repeat;
    background-size: 100% 100%;
}

.fifthline .logo-b {
    width: 4.5vw;
    height: 4.5vw;
    background: url(../assets/logo-ff.png) no-repeat;
    background-size: 100% 100%;
}

.fifthline .logo-c {
    width: 5.8vw;
    height: 2vw;
    background: url(../assets/logo-ii.png) no-repeat;
    background-size: 100% 100%;
}

.fifthline .logo-d {
    width: 4vw;
    height: 4.5vw;
    background: url(../assets/logo-aa.png) no-repeat;
    background-size: 100% 100%;
}

.fifthline .logo-e {
    width: 3vw;
    height: 4.5vw;
    background: url(../assets/logo-hh.png) no-repeat;
    background-size: 100% 100%;
}

.fifthline .logo-f {
    width: 4.5vw;
    height: 4vw;
    background: url(../assets/logo-bb.png) no-repeat;
    background-size: 100% 100%;
}

.fifthline .logo-g {
    width: 4vw;
    height: 4.5vw;
    background: url(../assets/logo-dd.png) no-repeat;
    background-size: 100% 100%;
}

.fifthline .logo-h {
    width: 2.9vw;
    height: 4.3vw;
    background: url(../assets/logo-cc.png) no-repeat;
    background-size: 100% 100%;
}

.fifthline .logo-i {
    width: 4vw;
    height: 4.5vw;
    background: url(../assets/logo-ee.png) no-repeat;
    background-size: 100% 100%;
}

.secondpri {
    width: 100%;
    height: 15vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 6.5vw;
}

/* .secondline .mintbg{
    width: 10.5vw;
    height: 13.5vw;
    position:absolute;
    top: -1.5vw;
    right: -0.4vw;
} */

.mintbg:hover {
    width: 12vw;
    height: 15vw;
    transition: width 0.5s height 0.5s ease;
}

.minShadow {
    width: 10.5vw;
    height: 15vw;
    position: relative;
}

.minShadow:hover {
    width: 13vw;
    height: 18vw;
    transition: width 0.5s height 0.5s ease;
}

.mintbgA-S {
    background: url('../assets/NFT4.png') no-repeat;
    background-size: 100% 100%;
}

.mintbgB-S {
    background: url('../assets/NFT2.png') no-repeat;
    background-size: 100% 100%;
}

.mintbgC-S {
    background: url('../assets/NFT3.png') no-repeat;
    background-size: 100% 100%;
}

.mintbgD-S {
    background: url('../assets/NFT5.png') no-repeat;
    background-size: 100% 100%;
}

.mintbgE-S {
    background: url('../assets/NFT1.png') no-repeat;
    background-size: 100% 100%;
}

/* .mintbgA{
    background: url('../assets/NFT1.png') no-repeat;
    background-size: 100% 100%;
}
.mintbgB{
    background: url('../assets/NFT2.png') no-repeat;
    background-size: 100% 100%;
}
.mintbgC{
    background: url('../assets/NFT3.png') no-repeat;
    background-size: 100% 100%;
}
.mintbgD{
    background: url('../assets/NFT4.png') no-repeat;
    background-size: 100% 100%;
}
.mintbgE{
    background: url('../assets/NFT5.png') no-repeat;
    background-size: 100% 100%;
} */

.secondline .statistics {
    width: 45vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.secondline .statistics .num {
    width: 13vw;
    height: 9.375vw;
    border-radius: 0.25vw;
    margin: 2vw 0;
    padding: 0.7vw 1vw;
}

.flexpub {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flexpub-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flexpub-around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.centerpub {
    display: flex;
    justify-content: center;
    align-items: center;
}

.numpri {
    background-color: #B4E626;
    box-shadow: 0 .125vw .375vw 0 rgba(162, 197, 41, 0.5900);
}

.numpri-A {
    box-shadow: 0 .125vw .625vw .0625vw rgba(162, 197, 41, 0.5900);
}

.secondline .statistics .linegreen {
    width: 6.25vw;
    height: .15vw;
    background-color: #B4E626;
}

.plac {
    width: 2vw;
    height: 9.375vw;
}

.num .dotpub {
    width: .3vw;
    height: .3vw;
    border-radius: 50%;
    box-shadow: 0 .125vw .2vw 0 #489E3F;
}

.dotleft {
    background-color: #fff;
}

.dotright {
    background-color: #489E3F;
}

.num .zeropub {
    font-size: 4vw;
    font-family: Futura-Medium, Futura;
    font-weight: 500;
}

.num .numtitle {
    font-size: .75vw;
    font-family: Futura-Medium, Futura;
    font-weight: 500;
    text-align: center;
}

.zeroleft {
    color: #fff;
    text-shadow: .0625vw .3125vw .4375vw rgba(72, 158, 63, 0.8800);
}

.zeroright {
    color: #B4E626;
    text-shadow:
        .0625vw .3125vw .4375vw #489E3F,
        0 0 1vw #F9FA01;
}

.secondline .success {
    font-size: .75vw;
    font-family: Futura-Medium, Futura;
    font-weight: 500;
    color: #A2C529;
    cursor: pointer;
    user-select: none;
}

.successTop {
    margin-top: 1vw;
}

.secondline .successlist {
    width: 100%;
    margin: 1vw 0;
    padding: 0.5vw 0;
    background-color: #f9f9f9;
    border-radius: .25vw;
    font-family: Futura-Medium, Futura;
    font-weight: 500;
    color: #88D189;
}

.success img {
    width: .9vw;
    height: .8vw;
    vertical-align: middle;
    margin: 0 0 .1vw 0.3vw;
}

.tabledata {
    width: 100%;
    height: 4.25vw;
    background-color: #f9f9f9;
    font-size: .9vw;
    font-family: Futura-Medium, Futura !important;
    color: #88D189;
    text-align: center;
    margin: 1vw 0 .125vw;
}

/* .tabledataunfold{
    transition: height 0.3s linear;
    height: .625vw !important;
}
 */
.tabledata th {
    font-weight: 500 !important;
    border-top: .125vw solid #fff;
    padding: 0.5vw 0;
}

.more {
    padding: 0.2vw 0 !important;
    margin-top: 0 !important;
}

.moreImg {
    width: 1vw;
    height: 1vw;
}

.thpri {
    text-align: left !important;
    padding-left: 1vw !important;
}

.roadmapline {
    width: 100%;
    height: .2604vw;
    margin-top: 3vw;
    background: url('../assets/rectangle.png') no-repeat;
    background-size: 100% 100%;
}

.roadmapdot {
    width: 1vw;
    height: 1vw;
    background: url('../assets/dot.png') no-repeat;
    background-size: 100% 100%;
}

.roadmaptext {
    width: 100%;
}

.roadmaptext .textitem {
    width: 33.33%;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    position: relative;
}

.textitem-tit {
    font-size: 1.5vw;
    font-family: Futura-Bold, Futura;
    font-weight: bold;
    color: #A2C529;
    margin: .2604vw 1vw;
}

.textitem-text {
    font-size: 0.65vw;
    font-family: Futura-Medium, Futura;
    font-weight: 500;
    color: #A2C529;
    margin: 0 1vw .2604vw;
}

.lefttop {
    width: .9375vw;
    height: .9375vw;
    background: url('../assets/roadmap-left.png') no-repeat;
    background-size: 100% 100%;
    margin-top: 2vw;
}

.rightbottom {
    width: .9375vw;
    height: .9375vw;
    background: url('../assets/roadmap-right.png') no-repeat;
    background-size: 100% 100%;
    /* float: right; */
    position: absolute;
    right: 0.5vw;
    bottom: 2vw;
}

.triangle-A {
    width: 1vw;
    height: 1vw;
    background: url('../assets/triangle-A.png') no-repeat;
    background-size: 100% 100%;
    margin: 2vw auto 0;
}






.secondline .status {
    font-size: 1.2vw;
    font-family: Futura-Medium, Futura;
    font-weight: 500;
    color: #A2C529;
    text-align: left;
    margin: 1vw 0 0;
}

.secondline .levellist {
    width: 30vw;
    font-size: 0.85vw;
    font-family: Futura-Medium, Futura;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    margin: 1.5vw auto 1vw;
    position: relative;
}

.secondline .levellistpub {
    /* width: 100%; */
    height: 3vw;
    line-height: 3vw;
    border-radius: 0.25vw;
    margin: 0 auto;
}

.secondline .levellist-A {
    /* background: linear-gradient(180deg, #B2DBAC 0%, #B3E620 43%, #D3DA85 100%); */
    width: 15.5vw;
    background: url('../assets/rectangle7.png') no-repeat;
    background-size: 100% 100%;
}

.secondline .levellist-B {
    /* background: #B4E626;
    opacity: 0.8; */
    width: 18.875vw;
    background: url('../assets/rectangle10.png') no-repeat;
    background-size: 100% 100%;
}

.secondline .levellist-C {
    /* background: #B4E626;
    opacity: 0.6; */
    width: 22.25vw;
    background: url('../assets/rectangle11.png') no-repeat;
    background-size: 100% 100%;
}

.secondline .levellist-D {
    /* background: #B4E626;
    opacity: 0.4; */
    width: 25.625vw;
    background: url('../assets/rectangle12.png') no-repeat;
    background-size: 100% 100%;
}

.secondline .levellist-E {
    /* background: #B4E626;
    opacity: 0.3; */
    width: 29vw;
    background: url('../assets/rectangle13.png') no-repeat;
    background-size: 100% 100%;
}

.warning {
    width: 28vw;
    font-size: 0.8vw;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FC7309;
    margin: 0.5vw auto 5vw;
    text-align: center;
    white-space: nowrap;
}

.here {
    font-size: 0.8vw;
    font-family: Futura-Medium, Futura;
    font-weight: bold;
    color: #A2C529;
    position: absolute;
}

.triangle {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: .45vw solid transparent;
    border-bottom: .45vw solid transparent;
    border-right: .7vw solid #B4E626;
    margin: -0.15vw 0.8vw 0 0;
    vertical-align: middle;
    /* box-shadow: 0vw .1042vw .3125vw 0vw rgba(253,244,54,0.6900); */
}

.invsize {
    font-size: 1.2vw;
    margin-top: 1vw !important;
}

.nodata {
    font-size: 0.9vw;
    text-align: center;
}

.litterstatus {
    font-size: 0.9vw !important;
    margin: 0;
}

.partnerbg {
    width: 100vw;
    /* height: 7vw; */
    background: url(../assets/partnerbg.png) no-repeat;
    background-size: 100% 100%;
    margin: 2vw 0 7vw;
}

.partnerbg .cover-A {
    width: 22vw;
    /* height: 7vw; */
    background-color: red;
    z-index: 999;
}

.parterlist {
    width: 100vw;
    /* height: 7vw; */
    margin: 0 auto;
    animation: 20s wordsLoop linear infinite normal;
    -webkit-animation: 20s wordsLoop linear infinite normal;
    /* overflow-x:scroll; */
}

.parterlist-S {
    width: 100vw;
    /* height: 7vw; */
    margin: 0 auto;
    overflow: hidden;
}

.parterlist:hover {
    /* 当鼠标放在图片上的时候能够暂停动画 */
    animation-play-state: paused;
}

.parteritem {
    width: 5vw;
    height: 3vw;
    flex-shrink: 0;
    /* 因为需要计算动画效果宽度所以不建议修改左右外边距 */
    /* 如果必须修改 必须修改scrolltest方法中的值 */
    margin: 1.0417vw .5208vw;
}

.parteritem img {
    max-width: 100%;
    max-height: 100%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes show {
    0% {
        transform: translate(0, 65vw);
        -webkit-transform: translate(0, 65vw);
        /* Safari and Chrome */
    }

    100% {
        transform: translate(0, 0);
        -webkit-transform: translate(0, 0);
        /* Safari and Chrome */
    }
}

.mediabox {
    width: 65vw;
    /* height: 20vw; */
    margin: 3vw auto 7vw;
}

.media-P {
    width: 4.5vw;
    height: 6.3vw;
    cursor: pointer;
}

.media-L {
    background: url(../assets/media-L.png) no-repeat;
    background-size: 100% 100%;
    margin-right: 1vw;
}

.media-R {
    background: url(../assets/media-R.png) no-repeat;
    background-size: 100% 100%;
    margin-left: 1vw;
}

.mediatext {
    width: 54vw;
    /* height: 20vw; */
}

.mediaitem {
    width: 18vw;
    background: url(../assets/mediabg.png) no-repeat;
    background-size: 100% 100%;
    padding: 1.5vw 2vw;
}

.mediaShow {
    display: none;
    animation: show 0.5s;
}

.gridpub {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, 18vw);
    /* grid-gap: 1.25rem; */
}

.mediatitle {
    width: 13vw;
    margin: 0 auto;
    text-align: center;
    font-size: 0.95vw;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #A2C529;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
}

.mediacontent {
    width: 13vw;
    margin: 0.5vw auto;
    text-align: left;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 500;
    color: #A2C529;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 14;
    -webkit-box-orient: vertical;
    user-select: none;
}

.mediacontent p {
    font-size: 0.65vw !important;
    font-family: PingFangSC-Semibold, PingFang SC !important;
    font-weight: 500 !important;
}

.mediacontent img {
    width: 0.8vw;
    margin-left: 0.2vw;
}

.mediacontent-aa {
    text-align: right;
    font-size: 0.75vw;
    cursor: pointer;
}

.youown {
    width: 2.3vw;
    height: 2.3vw;
    position: absolute;
    top: -0.9vw;
    right: -0.9vw;
}

/* @media (min-width:27.1354vw) and (max-width:32.2917vw){
    .mediacontent{
        -webkit-line-clamp: 3;
    }
}

@media (min-width:32.2917vw) and (max-width:37.5vw){
    .mediacontent{
        -webkit-line-clamp: 4;
    }
}

@media (min-width:37.5vw) and (max-width:42.7083vw){
    .mediacontent{
        -webkit-line-clamp: 5;
    }
}

@media (min-width:42.7083vw) and (max-width:47.9167vw){
    .mediacontent{
        -webkit-line-clamp: 6;
    }
}

@media (min-width:47.9167vw) and (max-width:53.125vw){
    .mediacontent{
        -webkit-line-clamp: 7;
    }
}

@media (min-width:53.125vw) and (max-width:58.3333vw){
    .mediacontent{
        -webkit-line-clamp: 8;
    }
}

@media (min-width:53.1771vw) and (max-width:58.3333vw){
    .mediacontent{
        -webkit-line-clamp: 9;
    }
}

@media (min-width:58.3854vw) and (max-width:63.5417vw){
    .mediacontent{
        -webkit-line-clamp: 10;
    }
}

@media (min-width:63.5938vw) and (max-width:68.75vw){
    .mediacontent{
        -webkit-line-clamp: 11;
    }
}

@media (min-width:68.8021vw) and (max-width:73.9583vw){
    .mediacontent{
        -webkit-line-clamp: 12;
    }
}

@media (min-width:79.2188vw) and (max-width:84.375vw){
    .mediacontent{
        -webkit-line-clamp: 13;
    }
} */






















/* 适配H5 */
@media (max-width:27.0833vw) {
    .bgtop {
        width: 100%;
        /* height: 150vw; */
        height: 170vw;
        background: url(../assets/h5-bg.png) no-repeat;
        background-size: 100% 100%;
        padding: 3vh 2vw 40vw;
    }

    .title {
        font-size: 7vw;
    }

    .inv {
        font-size: 6vw;
        margin-left: 2vw;
    }

    .ite {
        font-size: 5.6vw;
    }

    .logolist {
        position: absolute;
        right: 28vw;
        margin: -6.2vw 1.25vw 0 0;
    }

    .logolist img {
        width: 3.3vw;
        height: 3.3vw;
    }

    .earthbox {
        position: absolute;
        right: 43vw;
        margin: -5.6vw 1vw 0 0;
    }

    .threePri-Buy {
        font-size: 3vw;
        font-family: Poppins-Medium, Poppins;
        font-weight: bold;
    }

    .earth {
        width: 3vw;
        height: 3vw;
    }

    .head-connect {
        width: 25vw;
        height: 4vw;
        margin: -6.3vw 2vw 2vw 0;
        border: .0521vw solid #fff;
        border-radius: 2vw;
    }

    .stepall {
        flex-flow: column;
        align-items: center;
    }

    .stepall .step {
        width: 54.4vw;
        height: 54.4vw;
        margin: 4vw 0;
    }

    .stepall .step img {
        width: 20vw;
        height: 20vw;
        margin: 6vw auto 5vw;
    }

    .stepall .step .textstep {
        font-size: 4vw;
    }

    .stepall .steptext {
        width: 54.4vw;
        font-size: 3vw;
    }

    .right-box .firstline {
        margin: 7vh auto 7vw;
    }

    .right-box .thirdlyline {
        /* height: 18.2292vw; */
        padding: 9vw 2vw 18vw 3vw;
    }

    .linewidth {
        width: 90.7vw;
    }

    .linewidth-A {
        width: 90vw;
    }

    .fourthlyline .rightlist-Q {
        position: relative;
        /* margin-top: 2vw; */
    }

    .right-box .fourthlyline .questionlist {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        user-select: none;
    }

    .fourthlyline .question {
        width: 90vw;
        font-size: 3.3vw;
        padding: 2vw 3vw;
        margin: .3646vw auto 0;
        border-radius: 5vw;
    }

    /* .questionhigh{
        width: 90vw;
        font-size: 3vw;
        padding: 0.5vw 3vw;
        margin: 3vw auto 0;
        border-radius: 5vw;
    } */

    /* .questionhigh img{
        width: 1vw;
        height: 1vw;
        margin: 3.8vw 0 0 3vw !important;
    } */

    .korSize {
        font-size: 3.2vw;
    }

    .enSize {
        font-size: 3.1vw !important;
        font-family: Futura-Medium, Futura !important;
    }

    .linewidth-B {
        width: 95vw;
    }

    .textleft {
        width: 75vw;
        margin-left: 0.5vw;
    }

    .img-Q img {
        width: 3vw;
        height: 3vw;
        /* float: right; */
        margin: 0.7vw 0 0 3vw !important;
    }



    .Pri {
        font-size: 5.5vw;
    }

    .onePri {
        font-size: 8.5vw;
    }

    .Pri3 {
        font-size: 4vw;
    }

    .Pri33 {
        font-size: 3.7vw;
    }

    .twoPri {
        font-size: 4vw;
    }

    .threePri {
        font-size: 4.5vw;
        margin: 3vw auto 5vw;
    }

    .firstline .firstline-right .textPub-a {
        font-size: 4vw;
    }

    .firstline .firstline-right .textPub-b {
        font-size: 4.5vw;
    }


    .linkbox {
        width: 65vw;
        font-size: 3vw;
        border-radius: 3vw;
        border: .0521vw solid #FFFFFF;
    }

    .copyBtn {
        width: 24vw;
        height: 6vw;
        font-size: 3vw;
        margin: 8vw auto 2vw;
        /* line-height: 6vw; */
        /* border-radius: 1.5vw; */
        border-radius: 3vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .buyBtn {
        margin-top: 3vw !important;
    }

    .bottomPub {
        margin-bottom: 6vw !important;
    }

    .copyBtn .link {
        width: 3vw;
        height: 3vw;
        margin-right: 1vw;
    }

    .mintBtn {
        width: 25vw;
        height: 7.5vw;
        /* line-height: 7.5vw; */
        font-size: 4vw;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1.5vw;
    }

    .fifthline {
        height: 22vw;
        margin-top: 10vw;
        padding: 2vw 3vw 0;
    }

    .answer {
        line-height: 5vw !important;
        font-size: 2.8vw;
        margin-left: 5.5vw !important;
    }

    .right-box .firstline {
        width: 85vw;
    }

    .linewidth-C {
        width: 60vw;
    }

    .secondline .minShadow {
        width: 60vw;
        height: 85vw;
    }

    .secondline .statistics {
        width: 90vw;
    }

    .secondline .statistics .linegreen {
        width: 12vw;
        height: 0.3vw;
    }

    .secondline .statistics .num {
        width: 28vw;
        height: 22vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .num .numtitle {
        font-size: 3vw;
        margin: 1vw auto;
    }

    .num .zeropub {
        font-size: 8vw;
        margin: 0 7vw;
    }

    .num .dotpub {
        width: 0.8vw;
        height: 0.8vw;
    }

    .right-box .secondline {
        margin: 8vw auto;
    }

    .secondline .levellist {
        width: 70vw;
    }

    .secondline .levellist-A {
        width: 32.5vw;
    }

    .secondline .levellist-B {
        width: 39.875vw;
    }

    .secondline .levellist-C {
        width: 47.25vw;
    }

    .secondline .levellist-D {
        width: 54.625vw;
    }

    .secondline .levellist-E {
        width: 62vw;
    }

    .secondline .levellistpub {
        font-size: 3.5vw;
        height: 9vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .warning {
        width: 65vw;
        font-size: 2vw;
    }

    .here {
        position: absolute;
        /* right: -20vw; */
        font-size: 2vw;
    }

    .triangle {
        border-top: 1.45vw solid transparent;
        border-bottom: 1.45vw solid transparent;
        border-right: 1.7vw solid #B4E626;
        margin: -0.5vw 1.5vw 0 0;
    }

    .littertitle {
        font-size: 6vw;
    }

    .stepall .steptitle {
        font-size: 4vw;
        margin-top: 2vw;
    }

    .circleimg {
        width: 0;
        height: 0;
    }

    .thirdlyline .abouttext {
        font-size: 3vw;
    }

    .fifthline .logo-a {
        width: 8vw;
        height: 9vw;
    }

    .fifthline .logo-b {
        width: 8.5vw;
        height: 9vw;
    }

    .fifthline .logo-c {
        width: 12vw;
        height: 5vw;
    }

    .fifthline .logo-d {
        width: 8vw;
        height: 9vw;
    }

    .fifthline .logo-e {
        width: 7vw;
        height: 9vw;
    }

    .fifthline .logo-f {
        width: 8.5vw;
        height: 7vw;
    }

    .fifthline .logo-g {
        width: 8vw;
        height: 9vw;
    }

    .fifthline .logo-h {
        width: 6.5vw;
        height: 9vw;
    }

    .fifthline .logo-i {
        width: 8vw;
        height: 9vw;
    }

    .invsize {
        font-size: 3.5vw;
    }

    .secondline .success {
        font-size: 4vw;
    }

    .successTop {
        margin-top: 4vw;
    }

    .success img {
        width: 3.5vw;
        height: 3.2vw;
        margin: -0.7vw 0 0vw 1vw;
    }

    .tabledata {
        font-size: 3vw;
    }

    .tabledata th {
        border-top: 0.5vw solid #fff;
        padding: 1.5vw 0;
    }

    .nodata {
        font-size: 3vw;
    }

    .secondline .status {
        font-size: 4vw;
    }

    .litterstatus {
        font-size: 3.5vw !important;
        margin-bottom: 2.5vw !important;
    }

    .languagelist {
        display: none;
        width: 16vw;
        background: #fff;
        opacity: 0.82;
        position: absolute;
        right: 37.3vw;
        margin-top: -1vw;
    }

    .languagelist .lan {
        width: 100%;
        height: 7vw;
        font-size: 3.3vw;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #A2C529;
        cursor: pointer;
        user-select: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .laned {
        background: #A2C529 !important;
        opacity: 0.47 !important;
        color: #fff !important;
    }

    .earthbox:hover+.languagelist {
        display: block;
    }

    .languagelist:hover {
        display: block;
    }

    .parteritem {
        width: 10vw;
        height: 7vw;
    }

    .mediabox {
        width: 90vw;
    }

    .mediatext {
        width: 75vw;
    }

    .mediaitem {
        width: 70vw;
        padding: 7vw 2vw;
    }

    .mediatitle {
        width: 80%;
        font-size: 4vw;
    }

    .mediacontent-h5 {
        width: 80%;
        margin: 0.5vw auto;
        text-align: left;
        font-size: 3vw;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 500;
        color: #A2C529;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 14;
        -webkit-box-orient: vertical;
        user-select: none;
    }

    .mediacontent-h5 img {
        width: 4vw;
        margin-left: 0.2vw;
    }

    .mediacontent-aa {
        text-align: right;
        font-size: 3vw;
        cursor: pointer;
    }

    .media-P {
        width: 8vw;
        height: 11.2vw;
    }

    .fourthlyline-h5 {
        width: 100%;
        margin-bottom: 2.6042vw;
    }

    .roadmaptext {
        width: 80%;
        padding: 0 5vw;
    }

    .roadmap-h5 {
        width: 100%;
        /* height: 345vw; */
        height: 75.5208vw;
        margin-top: 1.0417vw;
    }

    .roadmaptext .textitem {
        width: 100%;
    }

    .roadmapline-h5 {
        width: 1vw;
        height: 100%;
        margin-top: 3vw;
        background: url('../assets/rectangle-h5.png') no-repeat;
        background-size: 100% 100%;
    }

    .lefttop {
        width: 5vw;
        height: 5vw;
        margin: 3vw 0 2vw;
    }

    .rightbottom {
        width: 5vw;
        height: 5vw;
        bottom: -5vw;
    }

    .textitem-tit {
        font-size: 6vw;
        margin: 0 5vw;
        vertical-align: middle;
    }

    .textitem-text {
        font-size: 0.85rem;
        margin: 5vw;
    }

    .roadmapdot {
        width: 6vw;
        height: 6vw;
        display: inline-block;
        margin: -1vw 7.5vw 0 -13.5vw;
    }

    .youown {
        width: 10vw;
        height: 10vw;
        position: absolute;
        top: 3vw;
        right: 3.5vw;
    }
}