.homeBg {
    width: 100%;
    min-height: 100vh;
    background-color: #000;
    position: relative;
    font-family: AaHouDiHei;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.homeBg .aboutBanner {
    width: 100%;
    height: 51.25vw;
}

img {
    width: 100%;
    height: 100%;
}

.moduleTwo {
    height: 43.9583vw;
    background: #FFF;
    color: #333333;
    justify-content: space-between;
    position: relative;
}
.moduleTwo img{
    width: 100%;
    height: 100%;
}
.moduleTwo .person1{
    width: 34.9479vw;
    height: 43.4375vw;
    position: absolute;
    bottom: -11.4583vw;
    right: 6.917vw;
    z-index: 99;
}

.oAo {
    width: 100%;
    height: 26.875vw;
}


.fouthMoudel {
    width: 100%;
}

.fouthMoudel .about_2 {
    position: relative;
}

.fouthMoudel .about_3 {
    position: relative;
}

.fouthMoudel .about_2 .person2 {
    width: 46.1979vw;
    height: 47.9688vw;
    position: absolute;
    bottom: -2.5521vw;
    left: 2.1354vw;
    z-index: 99;
}

.fouthMoudel .about_3 .person3 {
    width: 50.8333vw;
    height: 38.4375vw;
    position: absolute;
    bottom: 2.7604vw;
    right: .5208vw;
}

.audioMp3{
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
}

.audioMp3 .aplayer{
    margin: 0;
}