@font-face {
  font-family: 'AaHouDiHei';
  /* src: url("./fonts/3.Aa厚底黑.ttf"); */
}

/* @media only screen and (max-width: 520px) {
  .html{
    background: url('./assets/bg-mob.png');
    background-size: 100% auto;
  }
} */

iframe
{
    display: none;
}

body,html {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'Codec Pro' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #02121F;
  overflow-x: hidden;
}

p {
  font-family: poppins !important;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
    font-family: poppins;
}

.flexpub{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stroked-button-text {
    text-shadow:
     -1px -1px 0 #000,  
     1px -1px 0 #000,
     -1px 1px 0 #000,
     1px 1px 0 #000,
     -1px -2px 0 #000,  
     1px -2px 0 #000,
     -1px 2px 0 #000,
     1px 2px 0 #000,
     -2px -1px 0 #000,  
     2px -1px 0 #000,
     -2px 1px 0 #000,
     2px 1px 0 #000,
     2px 2px 2px rgba(0,0,0,1.0);
}

@media only screen and (min-width: 360px) {
  html {
      font-size: 12px;
  }
}

@media only screen and (min-width: 375px) {
  html {
      font-size: 13px;
  }
}

@media only screen and (min-width: 390px) {
  html {
      font-size: 14px;
  }
}

@media only screen and (min-width: 414px) {
  html {
      font-size: 15px;
  }
}

@media screen and (min-width: 640px) {
  html {
      font-size: 16px;
  }
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
}

input[type="number"] {
-moz-appearance: textfield;
}

.ant-message .anticon{
  display: none
}
.ant-message .ant-message-notice .ant-message-notice-content {
    background: cadetblue;
    color: #fff;
    border-radius: 10px;
}

.ant-notification-notice {
  background: #333 !important;
  border-radius: 12px;
}
.ant-notification-notice-message {
  color: #fff;
}
.ant-notification-notice-description {
  color: #fff;
}
.ant-notification .ant-notification-notice .ant-notification-notice-close {
  color: #fff !important;
  top: 12px;
  right: 12px;
}
